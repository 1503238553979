<template>
  <!-- power权限管理 -->
  <div id="Power">
    <!-- Dialog 对话框 -->
    <el-dialog :title="openType === 'add' ? '添加权限' : '编辑权限'"
               :visible.sync="isShowDialogVisible"
               :show-close="false">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelPowerForm(openType)">取 消</el-button>
        <el-button type="primary"
                   @click="subPowerForm(openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="addPowerForm()"
                     icon="el-icon-circle-plus-outline">添加</el-button>
          <!-- <el-button type="danger" size="small" icon="el-icon-delete" @click="delPowerGroup()">删除</el-button> -->
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="formLabel"
                    @blurInput="blurInput"
                    inline>
          <el-button type="primary"
                     @click="searchFormName(searchForm.keyword)">搜索</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <!-- <template v-slot:content>
        <el-row v-for="item in tableData"
                :key="item.id">
          <el-button type="text"
                     disabled>{{ item.parent }}</el-button>
          <el-button type="text">{{ item.name }}</el-button>
          <el-divider content-position="left">权限</el-divider>
          <el-row>
            <el-col v-for="citem in item.permission"
                    :key="citem.id">
              <el-button type="success"
                         size="mini"
                         @click="editPower(citem.id)">{{ citem.name }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </template> -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage='getPage'
                     style="height:680px">
          <el-table-column label="操作"
                           width="130">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top"
                          style="color:#0486FE; cursor: pointer;">
                <span @click="editPower(scope.row)">编辑</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { metMenuList, getPowerData, methPowerData } from 'api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTable from 'components/CommonTable.vue'

export default {
  components: {
    CommonForm,
    CommonManageMain,
    CommonTable
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      openType: 'add',
      isShowDialogVisible: false,

      // form表单 基础数据
      openForm: {
        id: '',
        name: '',
        description: '',
        module: '',
        controller: '',
        action: '',
        behavior: '',
        permission_group_id: ''
      },
      openFormLabel: [
        {
          model: 'name',
          label: '权限名称'
        },
        {
          model: 'description',
          label: '备注'
        },
        {
          model: 'module',
          label: '模块'
        },
        {
          model: 'controller',
          label: '控制器'
        },
        {
          model: 'action',
          label: '方法名'
        },
        {
          model: 'behavior',
          label: '标识code'
        },
        {
          model: 'permission_group_id',
          label: '关联组'
        }
      ],

      // 搜索
      searchForm: {
        keyword: ''
      },
      formLabel: [
        {
          model: 'keyword',
          label: '名字',
          labelOFF: true,
          options: []
        }
      ],
      tableLabel: [
        {
          prop: 'id',
          label: '权限ID',
          width: '180px'
        },
        {
          prop: 'name',
          label: '权限名称',
          width: '180px'
        },
        {
          prop: 'description',
          label: '权限描述',
          width: '180px'
        },
        {
          prop: 'module',
          label: '模块',
          width: '180px'
        },
        {
          prop: 'controller',
          label: '控制器',
          width: '180px'
        },
        {
          prop: 'action',
          label: '方法名',
          width: '200px'
        },
        {
          prop: 'behavior',
          label: '权限组ID',
          width: '200px'
        },
        {
          prop: 'update_time',
          label: '更新时间',
          width: '200px'
        },
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch
        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false,
        //row key
        rowKey: 'date',
        //显示表格top按钮
        isShowTopBtn: false,
      },
      // 表格数据
      tableData: [],

      // 权限组列表
      MenuListData: [],
      // 权限列表
      PowerListData: []
    }
  },
  mounted () {
    this.getPowerData()
  },
  watch: {
    PowerListData: function (newVal, oldVal) {
      this.getPowerGroupList()
    },
    PowerListData: function () {
      this.tableData = this.dataFormatDeal(
        this.PowerListData,
        {
        },
        0
      )
    },
  },
  methods: {
    // 获取权限组列表
    // getPowerGroupList () {
    //   metMenuList().then(res => {
    //     this.MenuListData = res.data.data
    //     this.formPowerList()
    //   })
    // },

    //获得下列分类页的页数
    getPage () {
      this.getPowerData()
    },
    // 获取权限数据
    getPowerData () {
      getPowerData({
        page: this.tableConfig.page,
        page_rows: 10
      }).then(res => {
        const Data = res.data
        if (Data.code == 200) {
          this.$message({
            message: Data.message,
            type: 'success'
          })
          this.PowerListData = Data.data.list
          this.tableConfig.total = Data.data.total
        } else {
          this.$message({
            message: Data.message,
            type: 'error'
          })
        }
      })
    },
    // 权限操作（增加，修改）
    methPowerData (method, args) {
      methPowerData(method, args).then(res => {
        console.log(res)
        if ((res.data.code = 200)) {
          this.getPowerData()
        }
      })
    },

    // // 数据格式化渲染
    // formPowerList () {
    //   // 格式
    //   // [
    //   //   {
    //   //     id:3,
    //   //     name:'角色列表',
    //   //     parent_id:5,
    //   //     parent:'组织架构',
    //   //     code: "jieseliebiao",
    //   //     permission: [
    //   //       {
    //   //           "id": 3,
    //   //           "name": "权限组列表",
    //   //           "description": "权限组列表",
    //   //           "behavior": "list",
    //   //           "permission_group_id": 3
    //   //       },
    //   //     ]
    //   //   }
    //   // ]

    //   // 权限-所属组的id
    //   const powerlist = [
    //     ...new Set(this.PowerListData.map(item => item.permission_group_id))
    //   ]

    //   let itemMenuListData = []

    //   for (let i = 0; i < powerlist.length; i++) {
    //     this.MenuListData.map(item => {
    //       itemMenuListData = item.child.filter(mItem => {
    //         if (powerlist.includes(mItem.id)) {
    //           if (item.id == mItem.parent_id) {
    //             const permission = this.PowerListData.filter(pItem => {
    //               if (mItem.id == pItem.permission_group_id) {
    //                 return pItem
    //               }
    //             })
    //             mItem.parent = item.name
    //             mItem.permission = permission
    //           }
    //           return mItem
    //         }
    //       })
    //     })
    //   }
    //   // 表格所以数据
    //   this.tableData = itemMenuListData
    // },

    // 失去焦点执行
    blurInput (val) {
      if (!val) {
        // 渲染table 数据
        this.powerTableData()
      }
    },

    // 添加按钮
    addPowerForm () {
      this.isShowDialogVisible = true
    },

    // 取消提交按钮
    cancelPowerForm (type) {
      this.openForm = this.$options.data().openForm
      this.isShowDialogVisible = false
    },
    // 确定提交按钮
    subPowerForm (type, data) {
      console.log(type, data)
      // type == 'add'
      //   ? this.methPowerData('POST', val)
      //   : this.methPowerData('PUT', val)
      // this.cancelPowerForm()
      if (type == 'add') {
        const DATA = {
          name: data.name,
          description: data.description,
          module: data.module,
          controller: data.controller,
          action: data.action,
          behavior: data.behavior,
          permission_group_id: data.permission_group_id
        }
        console.log('add')
        this.methPowerData('POST', DATA)
      } else if (type == 'edit') {
        const DATA = {
          id: data.id,
          name: data.name,
          description: data.description,
          module: data.module,
          controller: data.controller,
          action: data.action,
          behavior: data.behavior,
          permission_group_id: data.permission_group_id
        }
        console.log('edit')
        this.methPowerData('PUT', DATA)
      }
      this.isShowDialogVisible = false
      this.getPowerData()
    },
    // 编辑操作按钮
    editPower (row) {
      this.openForm = {
        id: row.id,
        name: row.name,
        description: row.description,
        module: row.module,
        controller: row.controller,
        action: row.action,
        behavior: row.behavior,
        permission_group_id: row.permission_group_id
      }
      this.openType = 'edit'
      this.isShowDialogVisible = true
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },
  }
}
</script>

<style lang="scss" scoped>
#Power {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
}
.el-row {
  margin: 10px !important;
  box-sizing: border-box;
  .el-divider {
    margin: 16px 0;
  }
  .el-col {
    display: inline-block;
    margin: 10px;
  }
  .el-col.el-col-24 {
    width: auto;
  }
}
</style>
